@import "../../_globalColor";

.footer-text {
  text-align: center;
}

.footer-div {
  margin-top: 2rem;
  padding-bottom: 1rem;
}
